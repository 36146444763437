.section-clinicalreality {
  .nav-promo {
    @include start-breakpoint($medium-min) {
      display: none;
    }
  }
}

.clinique-reality-overlay {
  iframe {
    height: 380px;
  }
}

.clinical-reality__foundation-finder--products {
  .clinical-reality__foundation--carousel {
    top: 235px;
  }
}
